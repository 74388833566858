import { MantaBase } from 'site/mantabase';
export class CookieConsent {
    constructor(id) {
        this.manta = new MantaBase();
        this.$container = $(id);
        this.$accept = $(id).find('#CookieConsentAccept');
        this.$reject = $(id).find('#CookieConsentReject');
        this.init();
    }
    init() {
        let self = this;
        self.$accept.on("click", () => {
            let cookie = self.$accept.data("cookie-string");
            //console.log(cookie);
            document.cookie = cookie;
            location.reload();
            /*self.$container.remove();*/
        });
        self.$reject.on("click", () => {
            let cookie = self.$reject.data("cookie-string");
            //console.log(cookie);
            document.cookie = cookie;
            self.$container.remove();
        });
    }
}
